import { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import WhatsappFloatButton from "../components/WhatsappFloatButton";
import { Styles } from "../styles/styles";
import routes from "./config";
import { getCookie } from "../utils/getCookie";
import { setCookie } from "../utils/setCookie";

const Router = () => {
  const search = window.location.search ? window.location.search : window.location.href.split("?")[1];
  const queryParams = new URLSearchParams(search);
  const utm_source: string = queryParams.get("utm_source")!;
  const campaign: string = queryParams.get("campaign")!;
  let cookieUtmSource = getCookie("utm_source")
  let cookieCampaing = getCookie("campaign")

  if (!cookieUtmSource && utm_source) {
    setCookie("utm_source", utm_source, 21600000);
  }

  if (!cookieCampaing && campaign) {
    setCookie("campaign", utm_source, 21600000);
  }

  return (
    <Suspense fallback={null}>
      <Styles />
      <WhatsappFloatButton />
      <Header />
      <Switch>
        {routes.map((routeItem) => {
          return (
            <Route
              key={routeItem.component}
              path={routeItem.path}
              exact={routeItem.exact}
              component={lazy(() => import(`../pages/${routeItem.component}`))}
            />
          );
        })}
      </Switch>
      <Footer />
    </Suspense>
  );
};

export default Router;
