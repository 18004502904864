import styled from "styled-components";

export const StyledButton = styled("button") <any>`
  background: ${(p) => p.background || "#2D9A47"};
  color: ${(p) => (p.color ? p.color : "#fff")};
  font-size: 1.125rem;
  font-family: "Poppins SemiBold", sans-serif;
  width: 100%;
  border: 1px solid #edf3f5;
  border-radius: 4px;
  padding: 13px 5px;
  cursor: pointer;
  margin-left: ${(p) => (p.marginLeft ? p.marginLeft : "0px")};
  margin-top: 0.625rem;
  max-width: ${(p) => (p.fixedWidth ? p.fixedWidth : "180px")};
  transition: all 0.3s ease-in-out;
  box-shadow: 0 16px 30px rgb(23 31 114 / 20%);
  font-family: 'Poppins Regular';
  font-weight: 600;
  height: 45px;
  padding: 7px;
  line-height: 27px;

  :focus {
    border: 1px solid #2d9a47;
    background-color: #2d9a47;
    color: #FFF
  }

  :hover {
    border: 1px solid #004213;
    background-color: #004213;
    color: #FFF
  }

  :disabled {
    background-color: rgba(19, 1, 1, 0.3);
  }
`;

export const ButtonLoadingImage = styled("img")`
  width: 30px;
  transform: scale(1);
  animation: pulse 1s infinite;

  @keyframes pulse {
    0% {
      transform: scale(0.95);
    }
  
    70% {
      transform: scale(1.1);
    }
  
    100% {
      transform: scale(0.95);
    }
  }
`