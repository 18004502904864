import "antd/dist/antd.css";
import ReactDOM from "react-dom";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import Router from "./router";
import i18n from "./translation";

const App = () => {
  const options = {
    autoConfig: true,
    debug: false,
  };

  const trackingId = "UA-209748536-1";
  ReactGA.initialize(trackingId);

  ReactPixel.init("331032518883248", undefined, options);
  ReactPixel.pageView();

  return (
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <Router />
      </I18nextProvider>
    </BrowserRouter>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
