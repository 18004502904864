export enum Environments {
    REACT_APP_PORTAL_API_HOST = "https://api.cashgo.com.br/portal-api",
    REACT_APP_PORTAL_CLIENTE_API_HOST = "https://api.cashgo.com.br/portal-cliente-api",
    REACT_APP_PORTAL_AUTH_SERVER_HOST = "https://api.cashgo.com.br/auth-server-api",
    REACT_APP_PORTAL_HOST = "https://cliente.cashgo.com.br/#/antecipacao",
    REACT_APP_PORTAL_URL = "https://cliente.cashgo.com.br",
    REACT_APP_LANDING_PAGE_API_HOST = "https://api.cashgo.com.br/landing-page-api",

    REACT_APP_PORTAL_API_HOST_DEV = "https://dev-api.cashgo.com.br/portal-api",
    REACT_APP_PORTAL_CLIENTE_API_HOST_DEV = "https://dev-api.cashgo.com.br/portal-cliente-api",
    REACT_APP_PORTAL_AUTH_SERVER_HOST_DEV = "https://dev-api.cashgo.com.br/auth-server-api",
    REACT_APP_PORTAL_HOST_DEV = "https://dev.cliente.cashgo.com.br/#/antecipacao",
    REACT_APP_PORTAL_URL_DEV = "https://dev.cliente.cashgo.com.br",
    REACT_APP_LANDING_PAGE_API_HOST_DEV = "https://dev-api.cashgo.com.br/landing-page-api",

    REACT_APP_PORTAL_API_HOST_LOCAL = 'http://localhost:8082/portal-api',
    REACT_APP_PORTAL_CLIENTE_API_HOST_LOCAL = 'http://localhost:3000/portal-cliente-api',
    REACT_APP_PORTAL_AUTH_SERVER_HOST_LOCAL = 'http://localhost:8081/auth-server-api',
    REACT_APP_PORTAL_HOST_LOCAL = "http://localhost:3001/#/antecipacao",
    REACT_APP_PORTAL_URL_LOCAL = "http://localhost:3001",
    REACT_APP_LANDING_PAGE_API_HOST_LOCAL = "http://localhost:8080/landing-page-api",
}