import { MenuOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { StyledButton } from "../../common/Button/styles";

export const HeaderSection = styled("header")`
  position: fixed;
  top: 0px;
  width: 100%;
  background-color: #FFF;
  z-index: 1;

  .ant-row-space-between {
    align-items: center;
    text-align: center;
  }

  @media screen and (max-width: 414px) {
    padding: 0 27px 0 20px;
  }

  @media screen and (min-width: 415px) {
    > div {
      box-shadow: 0px 2px 2px #c4c4c4;
    }
  }
`;

export const EntrarButton = styled("button") <any>`
  font-size: 18px;
  font-weight: 700;
  height: 100px;
  width: 100px;
  border: none;
  cursor: pointer;
  color: #004213;
  background-color: #FFF;
  margin-right: 100px;
  font-family: "Poppins Bold", sans-serif;

  @media screen and (max-width: 768px) {
    max-width: 99%;
    height:40px;
    padding: 0;
    border-radius: 4px;
    margin-top: 40px; 
  }
`;

export const ButtonSimularAgora = styled(StyledButton) <any>`
  @media screen and (max-width: 768px) {
    height:40px;
    padding: 0;
    margin-top: 135px; 
  }
`;



export const LogoContainer = styled(Link)`
  max-width: 371px;
  max-height: 73px;
  margin-left: 100px;
  
  @media only screen and (max-width: 414px) {
    margin-left: 0px;
    max-width: 124px;
    max-height: 24px;
  }
`;

export const NavLink = styled("div")`
  display: inline-block;
  text-align: center;
`;

export const CustomNavLink = styled("div")`
  width: 203px;
  display: inline-block;

  @media only screen and (max-width: 414px) {
    width: 150px;
  }

  @media only screen and (max-width: 320px) {
    width: 118px;
  }
`;

export const ContactWrapper = styled("div") <any>`
  cursor: pointer;
  width: ${(p) => (p.width ? "100%" : "110px")};
  font-weight: 700;
  text-align: center;
  border-radius: 1.25rem;
  display: inline-block;
`;

export const Burger = styled("div")`
  @media only screen and (max-width: 890px) {
    display: block;
  }

  display: none;

  svg {
    fill: #2D9A47;
  }
`;

export const NotHidden = styled("div")`
  @media only screen and (max-width: 890px) {
    display: none;
  }
`;

export const Menu = styled("h5")`
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;

  @media  (max-width: 768px) {
    text-align: left;
  }
`;

export const CustomNavLinkSmall = styled(NavLink)`
  font-size: 1.2rem;
  color: #2D9A47
  width: 180px;
  transition: color 0.2s ease-in;
  padding: 0px 16px;
  margin-top: 0px;

  @media only screen and (max-width: 768px) {
    margin: 0;
    padding: 0;
    min-width: 100%;
  }
`;

export const CustomNavLinkBtnEntrar = styled(CustomNavLinkSmall)`
  margin: 0px;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const Label = styled("span")`
  font-weight: 500;
  color: #404041;
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const Outline = styled(MenuOutlined) <any>`
  font-size: 22px;
`;

export const Span = styled("span")`
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-size: 22px;

  &:hover,
  &:active,
  &:focus {
    text-underline-position: under;
    text-decoration: #2D9A47 underline;
  }
`;

export const Texto18VerdeBold = styled("p")`
    width: 100%;
    padding: 0.75rem 0 0.75rem;
    font-size: 18px;
    margin: 1.5rem 0 0 0;
    color: #2d9a47;
    font-family: "Poppins Bold", sans-serif;
`;

export const TextoItemMenuHamburger = styled(Texto18VerdeBold)`
    width: 100%;
    padding: 0.75rem 0 0.75rem;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    margin: 1.5rem 0 0 0;
    color: #2d9a47;
    font-family: "Poppins Bold", sans-serif;

    @media (max-width: 768px) {
      margin: 0;
      padding: 8px 0;
      text-align: left;
      font-size: 16px;
      color: #58595B;
    }
`;
