import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";

const verdeBackgroud = "#004213";
const fontColor = "#FFFFFF";

export const FooterSection = styled("footer")`
  background: ${verdeBackgroud};
  padding: 2.5rem 0;
`;

export const MainTitle = styled("div")`
  font-size: 24px;
  line-height: 36px;
  color: ${fontColor};
  margin-bottom: 25px;

  @media screen and (max-width: 414px) {
    padding: 1.5rem 0;
    font-size: 24px;
    margin-bottom: 0;
  }
`;

export const ContactTitle = styled('span')`
  color: ${fontColor};
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 18px;

  @media screen and (max-width: 414px) {
    font-size: 14px;
    margin-bottom: 24px;
  }
`;

export const LinksFooter = styled(Col)`
  display: block;

  @media screen and (max-width: 414px) {
    display: none;
  }
`;

export const RowLineSeparator = styled(Row)`
  display: block;

  @media screen and (max-width: 414px) {
    display: none;
  }
`;


export const TermosDeUsoContainerDefault = styled(Row)`
  @media screen and (max-width: 414px) {
    display: none;
  }
`;

export const TermosDeUsoContainerMobile = styled(Row)`
  display: none;
  @media screen and (max-width: 414px) {
    row-gap: 16px !important;
    display: flex;
    margin-top: 25px;
    row-gap: 16px;
  }
`;

export const MenuItem = styled.li({
  fontSize: "24px",
  color: fontColor,
  lineHeight: "36px"
});

export const LineSeparator = styled.div({
  backgroundColor: "#FFF",
  width: "100%",
  height: "8px"
});

export const Info = styled.span({
  color: fontColor,
  fontSize: "16px",
  lineHeight: "24px"
});

export const Title = styled("h4")`
  font-size: 22px;
  text-transform: capitalize;
  color: #2D9A47

;

  @media screen and (max-width: 414px) {
    padding: 1.5rem 0;
  }
`;

export const NavLink = styled(Link)`
  display: block;
  font-size: 1rem;
  margin-bottom: 0.625rem;
  transition: all 0.2s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    color: #15418e;
  }
`;

export const Extra = styled("section")`
  background: rgb(241, 242, 243);
  position: relative;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 2rem;
`;

export const LogoContainer = styled(Link)`
  display: flex;
`;

export const Para = styled("div")`
  color: #2D9A47

;
  font-size: 14px;
  width: 70%;
`;

export const Large = styled(Link) <any>`
  font-size: 16px;
  color: #000;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-transform: capitalize;
  line-height: 24px;
  display: block;
  margin-bottom: 0.625rem;
  transition: all 0.3s ease-in-out;
  max-width: max-content;

  &:hover {
    color: rgb(255, 130, 92);
    text-underline-position: under;
    text-decoration: rgb(255, 130, 92) wavy underline;
  }
`;

export const Chat = styled("p")`
  color: #2D9A47

;
  max-width: fit-content;
  border-bottom: 1px solid #2D9A47

;
  cursor: pointer;
  margin-top: 1rem;
  transition: all 0.3s ease-in-out;

  &:hover {
    border-bottom: 1px solid rgb(255, 130, 92);
    color: rgb(255, 130, 92);
  }
`;

export const Empty = styled("div")`
  position: relative;
  height: 53px;
`;

export const FooterContainer = styled("div")`
  padding: 0 40px;

`;

export const Label = styled("label")`
  font-size: 22px;
  text-transform: capitalize;
  color: #2D9A47

;
  display: block;
  margin-bottom: 2rem;
  font-family: "Motiva Sans Bold", serif;

  @media screen and (max-width: 414px) {
    padding: 1.5rem 0;
    margin-bottom: 1rem;
  }
`;

export const LanguageSwitch = styled("div") <any>`
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
`;

export const LanguageSwitchContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  width: 85px;
`;

export const SocialIcon = styled(FontAwesomeIcon)`
  color: #FFF;
  margin-right: 12px;
  font-size: 36px;
`;