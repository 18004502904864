import styled from "styled-components";
import { StyledContainer } from "../../common/Container/styles";

export const Container1480Px = styled(StyledContainer)<any>`
  max-width: 1480px;
`;

export const Container900Px = styled(StyledContainer)<any>`
  max-width: 900px;
`;
