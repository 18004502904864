import styled from "styled-components";

const verdeBackgroud = "#2D9A47";
const fontColor = "#FFFFFF";

export const WhatsappButton = styled.a`
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 1000;
  margin-left: auto;
  margin-right: auto;
  width: 60px;
  height: 60px;
  background-color: ${verdeBackgroud};
  color: ${fontColor} !important;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 1px 1px 4px #999;

  &.whatsapp-float-visible {
    display: flex;
  }

  &.whatsapp-float-invisible {
    display: none;
  }

  @media (max-width: 720px) {
    display: inline-block !important;
  }

  @media (min-width: 721px) {
    &.float-visible {
      display: inline-block !important ;
    }

    &.float-invisible {
      display: none !important;
    }
  }

  @media (max-width: 414px) {
    border: 2px solid #fff;
  }

  &:hover {
    filter: brightness(110%) !important;
    transform: rotate(15deg) scale(1.15) !important;
    cursor: pointer !important;
  }

  .whatsapp-float-icon {
    margin-top: 16px;
  }


`;
