import ReactGA from "react-ga";
import { isProduction } from "./environmentUtils";

const eventTrack = (category, action, label) => {
  if (isProduction()) {
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    });
  }
};

export { eventTrack };
