import { Col, Row } from "antd";
import styled, { createGlobalStyle } from "styled-components";

export const Styles = createGlobalStyle`

    @font-face {
        font-family: "Poppins Light";
        src: url("/fonts/Poppins-Light.ttf") format("truetype");
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: "Poppins Bold";
        src: url("/fonts/Poppins-Bold.ttf") format("truetype");
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: "Poppins Regular";
        src: url("/fonts/Poppins-Regular.ttf") format("truetype");
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: "Poppins SemiBold";
        src: url("/fonts/Poppins-SemiBold.ttf") format("truetype");
        font-style: normal;
        font-display: swap;
    }

    body,
    html,
    a {
        font-family: 'Poppins Light', sans-serif;
    }


    body {
        margin:0;
        padding:0;
        border: 0;
        outline: 0;
        background: #fff;
        overflow-x: hidden;
    }

    a:hover {
        color: #2D9A47

;
    }

    input,
    textarea {
        border-radius: 4px;
        border: 0;
        background: rgb(241, 242, 243);
        transition: all 0.3s ease-in-out;  
        outline: none;
        width: 100%;  
        padding: 1rem 1.25rem;

        :focus-within {
            background: none;
            box-shadow: #2e186a 0px 0px 0px 1px;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Poppins Bold', serif;
        color: #2D9A47

;
        font-size: 56px;
        line-height: 1.18;

        @media only screen and (max-width: 890px) {
          font-size: 47px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 32px;
        }
    }

    p {
        color: #2D9A47

;
        font-size: 21px;        
        line-height: 1.41;
    }

    h1 {
        font-weight: 600;
    }

    a {
        text-decoration: none;
        outline: none;
        color: #2E186A;

        :hover {
            color: #2e186a;
        }
    }
    
    *:focus {
        outline: none;
    }

    .about-block-image svg {
        text-align: center;
    }

    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-top: 1.5rem;
    }

    .ant-drawer-content-wrapper {
        width: 300px !important;
    }

    .youtube-player {
        position: relative;
        padding-bottom: 39.35%;
        height: 0;
        overflow: hidden;
        max-width: 69.99%;
        max-height: 50%;
        background: #fff;
        margin: 5px;
        width: 100%;

        @media screen and (max-width: 414px) {
            max-width: 100%;
            margin-bottom: 24px;
        }
      }
      
      .youtube-player iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        background: transparent;
        
      }
      
      .youtube-player img {
        object-fit: cover;
        display: block;
        left: 0;
        bottom: 0;
        margin: auto;
        max-width: 101%;
        width: 101%;
        position: absolute;
        right: 0;
        top: 0;
        border: none;
        height: auto;
        cursor: pointer;
        -webkit-transition: 0.4s all;
        -moz-transition: 0.4s all;
        transition: 0.4s all;
      }
      
      .youtube-player img:hover {
        -webkit-filter: brightness(75%);
      }
      
      .youtube-player .play {
        height: 72px;
        width: 72px;
        left: 50%;
        top: 50%;
        margin-left: -36px;
        margin-top: -36px;
        position: absolute;
        background: url("//i.imgur.com/TxzC70f.png") no-repeat;
        cursor: pointer;
      }

      .ant-select {
          height: 37px;
      }

      .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
        border-color: #40a9ff;
        border-right-width: 1px !important;
        height: 37px;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        height: 37px;
        position: relative;
        background-color: #fff;
        border: 1px solid black;
        border-radius: 2px;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .ant-select-selection-item {
        display:flex;
        align-items: center;
        font-size: 16px;
    }

    .hide-mobile {
        @media screen and (max-width: 414px) {
            display: none;
        }
    }

    .only-mobile {
        display: none;
        @media screen and (max-width: 414px) {
            display: block;
        }
    }

`;

export const Texto36BrancoBold = styled("p")`
    width: 100%;
    padding: 0.75rem 0 0.75rem;
    font-size: 36px;
    margin: 1.5rem 0 0 0;
    color: #fff;
    font-family: "Poppins Bold", sans-serif;

    @media only screen and (max-width: 414px) {
        font-size: 24px;
    }
`;


export const Texto18BrancoBold = styled("p")`
    font-size: 18px;
    color: #fff;
    font-family: "Poppins Bold", sans-serif;
`;

export const AvisoAntecipacaoContainer = styled(Row)`
    background-color: #58595B;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    @media only screen and (max-width: 414px) {
        display: none;
    }
`;

export const ImageCapaContainer = styled(Col)`
    position: relative;
    
    @media only screen and (max-width: 414px) {
        display: none;
    }
`;

export const Texto48Branco = styled("p")`
    font-size: 48px;
    color: #fff;
    font-family: "Poppins Light", sans-serif;
`;


export const Texto24Branco = styled("p")`
    width: 100%;
    padding: 0.75rem 0 0.75rem;
    font-size: 24px;
    margin: 1.5rem 0 0 0;
    color: #fff;
    font-family: "Poppins Light", sans-serif;

    @media only screen and (max-width: 414px) {
        font-size: 14px;
    }
`;



export const Texto36Branco = styled("p")`
    width: 100%;
    padding: 0.75rem 0 0.75rem;
    font-size: 36px;
    margin: 1.5rem 0 0 0;
    color: #fff;
    font-family: "Poppins Light", sans-serif;

    @media only screen and (max-width: 414px) {
        font-size: 18px;
    }
`;

export const Texto30Branco = styled("p")`
    width: 100%;
    padding: 0.75rem 0 0.75rem;
    font-size: 30px;
    margin: 1.5rem 0 0 0;
    color: #fff;
    font-family: "Poppins Light", sans-serif;

    @media only screen and (max-width: 414px) {
        font-size: 18px;
    }
`;

export const Texto30BrancoRegular = styled("p")`
    width: 100%;
    padding: 0.75rem 0 0.75rem;
    font-size: 30px;
    margin: 1.5rem 0 0 0;
    color: #fff;
    font-family: "Poppins Regular", sans-serif;

    @media only screen and (max-width: 414px) {
        font-size: 18px;
    }
`;

export const Texto18Verde = styled("p")`
    width: 100%;
    padding: 0.75rem 0 0.75rem;
    font-size: 18px;
    margin: 1.5rem 0 0 0;
    color: #2d9a47;
    font-family: "Poppins Light", sans-serif;
`;

export const Texto24Verde = styled("p")`
    width: 100%;
    padding: 0.75rem 0 0.75rem;
    font-size: 24px;
    margin: 1.5rem 0 0 0;
    color: #2d9a47;
    font-family: "Poppins Light", sans-serif;
`;

export const Texto36Verde = styled("p")`
    width: 100%;
    padding: 0.75rem 0 0.75rem;
    font-size: 36px;
    margin: 1.5rem 0 0 0;
    color: #2d9a47;
    font-family: "Poppins Light", sans-serif;

    @media only screen and (max-width: 414px) {
        font-size: 20px;
    }
`;

export const Texto36VerdeBold = styled(Texto36Verde)`
    font-family: "Poppins Bold", sans-serif;
    @media only screen and (max-width: 414px) {
        font-size: 24px;
    }
`;


export const Texto48VerdeBold = styled("p")`
    width: 100%;
    padding: 0.75rem 0 0.75rem;
    font-size: 48px;
    margin: 1.5rem 0 0 0;
    color: #2d9a47;
    font-family: "Poppins Bold", sans-serif;

    @media only screen and (max-width: 414px) {
        font-size: 24px;
    }
`;

export const Texto48VerdeRegular = styled("p")`
    width: 100%;
    padding: 0.75rem 0 0.75rem;
    font-size: 48px;
    margin: 1.5rem 0 0 0;
    color: #2d9a47;
    font-family: "Poppins Regular", sans-serif;

    @media only screen and (max-width: 414px) {
        font-size: 24px;
    }
`;

export const Texto48Verde = styled("p")`
    width: 100%;
    padding: 0.75rem 0 0.75rem;
    font-size: 48px;
    margin: 1.5rem 0 0 0;
    color: #2d9a47;
    font-family: "Poppins Light", sans-serif;

    @media only screen and (max-width: 414px) {
        font-size: 24px;
    }
`;

export const Texto16Vermelho = styled("p")`
    padding: 0.75rem 0 0.75rem;
    font-size: 16px;
    margin: 1.5rem 0 0 0;
    font-family: "Poppins Light", sans-serif;
`;

export const Texto18CinzaBold = styled("p")`
    font-size: 18px;
    margin: 1.5rem 0 0 0;
    color: #58595B;
    font-family: "Poppins Bold", sans-serif;
`;

export const Texto16Cinza = styled("p")`
    font-size: 16px;
    margin: 1.5rem 0 0 0;
    color: #58595B;
    font-family: "Poppins Light", sans-serif;
`;

export const Texto24Cinza = styled("p")`
    padding: 0.75rem 0 0.75rem;
    font-size: 24px;
    margin: 1.5rem 0 0 0;
    color: #58595B;
    font-family: "Poppins Light", sans-serif;

    @media only screen and (max-width: 414px) {
        font-size: 16px;
    }
`;

export const Texto24CinzaBold = styled("p")`
    padding: 0.75rem 0 0.75rem;
    font-size: 24px;
    margin: 1.5rem 0 0 0;
    color: #58595B;
    font-family: "Poppins Bold", sans-serif;

    @media only screen and (max-width: 414px) {
        font-size: 16px;
    }
`;

export const Texto36CinzaBold = styled("p")`
    padding: 0.75rem 0 0.75rem;
    font-size: 36px;
    margin: 1.5rem 0 0 0;
    color: #58595B;
    font-family: "Poppins Bold", sans-serif;
`;

