import { useEffect, useState } from "react";
import { useBetween } from "use-between";

const useCheckMobileScreen = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    let isMobile = width <= 768;

    return { isMobile };
}

const useSharedCheckMobileScreen = () => useBetween(useCheckMobileScreen);

export { useSharedCheckMobileScreen };
