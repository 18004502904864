import { WhatsappButton } from "./style";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const WhatsappFloatButton = () => {
  return (
      <WhatsappButton
        className="float-visible"
        target="_blank"
        href="https://api.whatsapp.com/send?phone=5511913083864&text=Ola!%20Fiz%20uma%20simula%C3%A7%C3%A3o%20no%20site%20e%20quero%20saber%20mais!"
      >
        <FontAwesomeIcon
          icon={faWhatsapp}
          style={{ fontSize: "24px" }}
          className="whatsapp-float-icon"
        />
      </WhatsappButton>
  );
};

export default WhatsappFloatButton;
