import {
  faFacebookSquare,
  faInstagramSquare,
  faLinkedin,
  faWhatsapp,
  faYoutubeSquare,
} from "@fortawesome/free-brands-svg-icons";
import { faCopyright, faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import { Image } from "../../common/Image";
import { Container1480Px } from "../../pages/Home/styles";
import {
  ContactTitle,
  FooterContainer,
  FooterSection,
  Info,
  LineSeparator,
  LinksFooter,
  LogoContainer,
  MainTitle,
  MenuItem,
  RowLineSeparator,
  SocialIcon,
  TermosDeUsoContainerDefault,
  TermosDeUsoContainerMobile,
} from "./styles";

const Footer = () => {
  return (
    <>
      <FooterSection>
        <Container1480Px>
          <FooterContainer>
            {/* <Row>
              <Col>
                <MainTitle>Fale com a gente</MainTitle>
              </Col>
            </Row> */}
            <Row justify="space-between">
              <Col
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <MainTitle>Fale com a gente</MainTitle>

                <ContactTitle>
                  <FontAwesomeIcon
                    icon={faWhatsapp}
                    style={{ fontSize: "24px", marginRight: "24px" }}
                  />
                  (11) 91308-3864
                </ContactTitle>
                <ContactTitle>
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    style={{ fontSize: "24px", marginRight: "24px" }}
                  />
                  atendimento@cashgo.com.br
                </ContactTitle>
                <ContactTitle>
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    style={{ fontSize: "24px", marginRight: "24px" }}
                  />
                  Rua Pequetita, 111. 2º andar, São Paulo - SP
                </ContactTitle>
              </Col>
              <Col>
                <MainTitle>Acompanhe</MainTitle>
                <Link
                  to={{ pathname: "https://www.linkedin.com/company/cashgooo" }}
                  target="_blank"
                  rel="noopener"
                >
                  <SocialIcon icon={faLinkedin} />
                </Link>
                <Link
                  to={{ pathname: "https://www.instagram.com/cashgo.oficial" }}
                  target="_blank"
                  rel="noopener"
                >
                  <SocialIcon icon={faInstagramSquare} />
                </Link>
                <Link
                  to={{ pathname: "https://www.facebook.com/cashgo.oficial" }}
                  target="_blank"
                  rel="noopener"
                >
                  <SocialIcon icon={faFacebookSquare} />
                </Link>
                <Link
                  to={{
                    pathname:
                      "https://www.youtube.com/channel/UCvyywqaVgJHa6fwTsRLpVrA",
                  }}
                  target="_blank"
                  rel="noopener"
                >
                  <SocialIcon icon={faYoutubeSquare} />
                </Link>
              </Col>
              <LinksFooter>
                <ul>
                  <Link to="/quem-somos">
                    <MenuItem>Quem somos</MenuItem>
                  </Link>
                  <Link to="/faq">
                    <MenuItem>Dúvidas (FAQ)</MenuItem>
                  </Link>
                  <Link to="/seja-parceiro">
                    <MenuItem>Imobiliárias</MenuItem>
                  </Link>
                  <Link to="/contato">
                    <MenuItem>Contato</MenuItem>
                  </Link>
                  <Link to="/carreira">
                    <MenuItem>Carreira</MenuItem>
                  </Link>
                  <Link to="/antecipacao-de-aluguel">
                    <MenuItem>O que é a antecipação de aluguel?</MenuItem>
                  </Link>
                </ul>
              </LinksFooter>
            </Row>
            <RowLineSeparator
              style={{ marginTop: "40px", marginBottom: "40px" }}
            >
              <Col offset={1} xs={22}>
                <LineSeparator />
              </Col>
            </RowLineSeparator>
            <TermosDeUsoContainerDefault
              justify={"space-around"}
              align={"middle"}
              style={{ marginBottom: "75px" }}
            >
              <Col xl={8}>
                <LogoContainer to="/" aria-label="homepage">
                  <Image
                    src="logo_cashgo_branco.webp"
                    width="100%"
                    height="100%"
                    alt="logo cashgo branco"
                    title="logo cashgo branco"
                    style={{ height: "auto" }}
                  ></Image>
                </LogoContainer>
              </Col>
              <Col xl={16}>
                <Row
                  justify={"space-between"}
                  style={{ maxWidth: "790px", width: "100%" }}
                >
                  <Col xl={10}>
                    <Info>
                      <FontAwesomeIcon
                        icon={faCopyright}
                        style={{ fontSize: "16px", color: "#FFF" }}
                      />{" "}
                      Copyright 2021. All Rights Reserved.
                    </Info>
                  </Col>
                  <Col xl={12}>
                    <Link to="/politica-de-privacidade">
                      <Info style={{ fontWeight: 600, fontSize: "18px" }}>
                        Politica de privacidade |{" "}
                      </Info>
                    </Link>
                    <Link to="/termos-de-uso">
                      <Info style={{ fontWeight: 600, fontSize: "18px" }}>
                        Termos de uso
                      </Info>
                    </Link>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Info>
                      42.544.764/0001-98 - ANTECIPA SOLUCOES FINANCEIRAS E
                      TECNOLOGIA LTDA
                    </Info>
                  </Col>
                </Row>
              </Col>
            </TermosDeUsoContainerDefault>
            <TermosDeUsoContainerMobile>
              <Col xl={24} xs={24}>
                <Link to="/politica-de-privacidade">
                  <Info style={{ fontWeight: 500, fontSize: "18px" }}>
                    Politica de privacidade
                  </Info>
                </Link>
              </Col>
              <Col xl={24} xs={24}>
                <Link to="/termos-de-uso">
                  <Info style={{ fontWeight: 500, fontSize: "18px" }}>
                    Termos de uso
                  </Info>
                </Link>
              </Col>
              <Col xl={24} xs={24}>
                <Info style={{ fontWeight: 400, fontSize: "16px" }}>
                  CNPJ: 42.544.764/0001-98
                </Info>
              </Col>
              <Col xl={24} xs={24}>
                <Info style={{ fontWeight: 400, fontSize: "16px" }}>
                  ANTECIPA SOLUCOES FINANCEIRAS E TECNOLOGIA LTDA
                </Info>
              </Col>

              <Col xl={10}>
                <LogoContainer to="/" aria-label="homepage">
                  <Image
                    src="logo_tagline_branco.webp"
                    width="100%"
                    height="100%"
                    style={{ height: "auto" }}
                  ></Image>
                </LogoContainer>
              </Col>

              <Col xl={10}>
                <Info>
                  <FontAwesomeIcon
                    icon={faCopyright}
                    style={{ fontSize: "16px", color: "#FFF" }}
                  />{" "}
                  Copyright 2021. All Rights Reserved.
                </Info>
              </Col>
            </TermosDeUsoContainerMobile>
          </FooterContainer>
        </Container1480Px>
      </FooterSection>
    </>
  );
};

export default Footer;
