import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Drawer, Row } from "antd";
import { useState } from "react";
import { withTranslation } from "react-i18next";
// import { Link } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import { StyledButton } from "../../common/Button/styles";
import { Image } from "../../common/Image";
import { Container1480Px } from "../../pages/Home/styles";
import { eventTrack } from "../../utils/gaEventUtiils";
import { reactPixelTrackCustom } from "../../utils/reactPixelUtils";
import { useSharedCheckMobileScreen } from "../../utils/useCheckMobileScreen";
import {
  Burger,
  ButtonSimularAgora,
  CustomNavLinkBtnEntrar,
  CustomNavLinkSmall,
  EntrarButton,
  HeaderSection,
  Label,
  LogoContainer,
  Menu,
  NotHidden,
  Outline,
  Span,
  TextoItemMenuHamburger,
} from "./styles";
const Header = ({ t }: any) => {
  const [visible, setVisibility] = useState(false);
  const { isMobile } = useSharedCheckMobileScreen();

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    return (
      <>
        <CustomNavLinkSmall>
          <Link to={"/quem-somos"}>
            <TextoItemMenuHamburger>{t("Quem somos")}</TextoItemMenuHamburger>
          </Link>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall>
          <Link to={"/seja-parceiro"}>
            <TextoItemMenuHamburger>{t("Imobiliárias")}</TextoItemMenuHamburger>
          </Link>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall>
          <Link
            to={"/#simulador"}
            scroll={(el) => {
              reactPixelTrackCustom("ClickSimularButtonMenu", "track");
              eventTrack("simulador", "clicked", "simulador_agora_menu");
              el.scrollIntoView({ behavior: "smooth", block: "center" });
            }}
          >
            <Span>
              <StyledButton
                key={1}
                color={"#FFF"}
                background={"#2D9A47"}
                fixedWidth={"250px"}
                style={{
                  padding: "none",
                  fontSize: "18px",
                  width: "120px",
                  height: "45px",
                  border: "1px solid transparent",
                }}
              >
                {t("Simular")}
              </StyledButton>
            </Span>
          </Link>
        </CustomNavLinkSmall>
        <CustomNavLinkBtnEntrar>
          <Span>
            <Link
              to={{
                pathname: "https://cliente.cashgo.com.br",
              }}
              target="_blank"
              rel="noopener"
            >
              <EntrarButton>
                {t("Entrar")}{" "}
                <FontAwesomeIcon icon={faSignInAlt}></FontAwesomeIcon>
              </EntrarButton>
            </Link>
          </Span>
        </CustomNavLinkBtnEntrar>
      </>
    );
  };

  const MenuItemMobile = () => {
    return (
      <>
        <CustomNavLinkSmall style={{ marginTop: "0px" }}>
          <Link to={"/quem-somos"}>
            <TextoItemMenuHamburger onClick={showDrawer}>
              {t("Quem somos")}
            </TextoItemMenuHamburger>
          </Link>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall style={{ marginTop: "0px" }}>
          <Link to={"/faq"}>
            <TextoItemMenuHamburger onClick={showDrawer}>
              {t("Dúvidas (FAQ)")}
            </TextoItemMenuHamburger>
          </Link>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall style={{ marginTop: "0px" }}>
          <Link to={"/seja-parceiro"}>
            <TextoItemMenuHamburger onClick={showDrawer}>
              {t("Imobiliárias")}
            </TextoItemMenuHamburger>
          </Link>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall style={{ marginTop: "0px" }}>
          <Link to={"/contato"}>
            <TextoItemMenuHamburger onClick={showDrawer}>
              {t("Contato")}
            </TextoItemMenuHamburger>
          </Link>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall style={{ marginTop: "0px" }}>
          <Link to={"/carreira"}>
            <TextoItemMenuHamburger onClick={showDrawer}>
              {t("Carreira")}
            </TextoItemMenuHamburger>
          </Link>
        </CustomNavLinkSmall>{" "}
        <CustomNavLinkSmall style={{ marginTop: "0px" }}>
          <Link to={"/antecipacao-de-aluguel"}>
            <TextoItemMenuHamburger onClick={showDrawer}>
              {t("o que é a antecipação de aluguel?")}
            </TextoItemMenuHamburger>
          </Link>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall style={{ marginTop: "0px" }}>
          <Link to={"/politica-de-privacidade"}>
            <TextoItemMenuHamburger onClick={showDrawer}>
              {t("Política de privacidade")}
            </TextoItemMenuHamburger>
          </Link>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall style={{ marginTop: "0px" }}>
          <Link to={"/termos-de-uso"}>
            <TextoItemMenuHamburger onClick={showDrawer}>
              {t("Termos de uso")}
            </TextoItemMenuHamburger>
          </Link>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall>
          <Link
            to={"/#simulador"}
            scroll={(el) => {
              reactPixelTrackCustom("ClickSimularButtonMenu", "track");
              eventTrack("simulador", "clicked", "simulador_agora_menu");
              el.scrollIntoView({ behavior: "smooth", block: "center" });
            }}
          >
            <Span>
              <ButtonSimularAgora onClick={showDrawer} fixedWidth={true}>
                {t("Simular agora")}
              </ButtonSimularAgora>
            </Span>
          </Link>
        </CustomNavLinkSmall>
        <CustomNavLinkBtnEntrar>
          <Span>
            <Link
              to={{
                pathname: "https://cliente.cashgo.com.br",
              }}
              target="_blank"
              rel="noopener"
            >
              <EntrarButton onClick={showDrawer}>
                {t("Entrar")}
                <FontAwesomeIcon icon={faSignInAlt}></FontAwesomeIcon>
              </EntrarButton>
            </Link>
          </Span>
        </CustomNavLinkBtnEntrar>
      </>
    );
  };

  return (
    <HeaderSection>
      <Container1480Px>
        <Row justify="space-between" style={{ minHeight: "50px" }}>
          <LogoContainer to="/" aria-label="homepage">
            <Image
              src="logo_cashgo.webp"
              width="100%"
              height="100%"
              alt="logo cashgo"
              title="logo cashgo"
              style={{ maxWidth: "260px", maxHeight: "73px" }}
            ></Image>
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={showDrawer}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} visible={visible} onClose={onClose}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={onClose}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          {isMobile ? <MenuItemMobile /> : <MenuItem />}
        </Drawer>
      </Container1480Px>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
