import ReactPixel from "react-facebook-pixel";
import { isProduction } from "./environmentUtils";

const reactPixelTrackCustom = (label, action) => {
  if (isProduction()) {
    ReactPixel.trackCustom(label, action);
  }
};

export { reactPixelTrackCustom };
