const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/carreira"],
    exact: true,
    component: "Carreira"
  },
  {
    path: ["/quem-somos"],
    exact: true,
    component: "QuemSomosWrapper"
  },
  {
    path: ["/faq"],
    exact: true,
    component: "Faq"
  },
  {
    path: ["/seja-parceiro"],
    exact: true,
    component: "SejaParceiro"
  },
  {
    path: ["/contato"],
    exact: true,
    component: "Contato"
  },
  {
    path: ["/politica-de-privacidade"],
    exact: true,
    component: "PoliticaDePrivacidade"
  },
  {
    path: ["/termos-de-uso"],
    exact: true,
    component: "TermosDeUso"
  },
  {
    path: ["/antecipacao-de-aluguel"],
    exact: true,
    component: "Posts/AntecipacaoDeAluguel"
  }
];

export default routes;
