const setCookie = (name: string, value: string, expireInMillis: number) => {
    var date = new Date();
    date.setTime(date.getTime() + expireInMillis);
    var expires = "; expires=" + date.toUTCString();
    document.cookie = name + "=" + (`${value}` || "") + expires + "; domain=.cashgo.com.br;";
}

const aceitarCookie = () => {
    document.cookie = "accepted_cookies=yes;"
}

const aceitouCookie = () => {
    console.log("accepted_cookies", document.cookie.indexOf("accepted_cookies=") < 0)
    return document.cookie.indexOf("accepted_cookies=") > 0;
}

export { setCookie, aceitarCookie, aceitouCookie };
