import { ImageProps } from "../types";

export const Image = ({
  src,
  width,
  height,
  style,
  title,
  alt,
}: ImageProps) => (
  <img
    src={`/img/icons/${src}`}
    alt={alt ? alt : src}
    title={title ? title : src}
    width={width}
    height={height}
    style={style}
  />
);
